@import "~/src/styles/style.scss";

.root {
	font-size: 12px;
	font-weight: 600;
	padding: 8px 0 8px 4px;
	color: $color-red;
	transition: background-color 0.3s ease-out, color 0.3s ease-out;
	background-color: transparent;
	border-radius: 4px;
	position: relative;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	line-height: 1;

	@include breakpoint(medium) {
		font-size: 14px;
		padding: 12px 0 12px 4px;
	}

	@include breakpoint(large) {
		font-size: 12px;
		padding: 4px 0 4px 4px;
	}

	&:hover {
		background-color: $color-red;
		color: $color-white;
		border-color: transparent;

		.iconWrapper {
			opacity: 1;
		}
	}

	&::after {
		content: '';
		position: absolute;
		width: calc(100% - 5px);
		justify-self: center;
		bottom: 0;
		border-bottom: 1px solid rgba($color-red, 0.3);
	}
}

.isActive {
	color: $color-white;

	&::after {
		border-color: rgba($color-white, 0.5);
	}
}

.iconWrapper {
	width: 16px;
	height: 16px;
	padding-right: 4px;
	opacity: 0;
	transition: all 0.3s ease-out;
}

.link {
	color: inherit;
	width: 100%;

	@include breakpoint(large) {
		line-height: 1.3;
	}
}