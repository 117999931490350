@import "~/src/styles/style.scss";

.root {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	width: 100%;
	height: 100%;
	padding: 16px 12px;
	border-radius: 10px;
	background-color: $color-gray;
	color: $color-red;
}

.isTabActive {
	background-color: $color-red;
	color: $color-white;
}

.link {
	display: flex;
	justify-content: space-between;
	font-weight: 700;
	color: $color-red;
}

.linkActive {
	color: $color-white;
}

.linkWrapper {
	flex-grow: 1;
}

.itemTitle {
	display: flex;
	justify-content: space-between;
	flex-grow: 1;
}

.additionalContent {
	position: absolute;
	bottom: 16px;
	left: 12px;
	width: calc(100% - 24px);
	z-index: 3;
}

