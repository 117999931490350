@import "~/src/styles/style.scss";

.root {
	background-color: $color-white;
	padding: 24px;
	width: calc(100% - 20px);
	margin: 0 auto;
	border-radius: 10px;

	@include breakpoint(medium) {
		max-width: 440px;
		width: 100%;
		margin: unset;
	}

	@include breakpoint(large) {
		max-width: 502px;
		padding: 32px;
	}

	@include breakpoint(xlarge) {
		max-width: 520px;
		padding: 16px;
	}

	@include breakpoint(desktop) {
		padding: 48px;
		max-width: 686px;
	}
}

.title {
	font-weight: 700;
	font-size: 26px;
	line-height: 26px;
	color: $color-red;
	margin-bottom: 8px;
	margin-top: 0;
	letter-spacing: -1px;

	@include breakpoint(medium) {
		font-size: 32px;
		line-height: 32px;
		letter-spacing: 0;
	}

	@include breakpoint(large) {
		font-size: 26px;
		line-height: 26px;
		margin-bottom: 12px;
		letter-spacing: -0.2px;
	}

	@include breakpoint(xlarge) {
		font-size: 32px;
		line-height: 32px;
		margin-bottom: 16px;
	}

	@include breakpoint(desktop) {
		font-size: 36px;
		line-height: 36px;
	}
}

.text {
	font-weight: 500;
	font-size: 14px;
	line-height: 120%;
	color: $color-red;

	@include breakpoint(medium) {
		font-size: 16px;
	}

	@include breakpoint(large) {
		font-size: 20px;
		line-height: 120%;
	}
}

.inputsWrapper {
	margin: 24px 0;
	cursor: pointer;
}

.inputWrapper {
	margin-bottom: 8px;
	display: flex;
	align-items: center;
	position: relative;

	&:hover {
		.inputText {
			color: $color-red;
		}
	}
}

.inputContent {
	position: absolute;
	left: 21px;
	display: flex;
	align-items: center;
}

.input {
	padding: 20px 24px 6px 46px;
	display: flex;
	align-items: center;
	position: relative;
	background-color: rgba($color-red, 0.1);
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	border-radius: 8px;
	color: $color-red;
	font-weight: 500;
	font-size: 17px;
}

.inputText {
	padding-left: 8px;
	color: rgba($color-red, 0.5);
	transition: color 0.3s;
	font-weight: 500;
	font-size: 17px;
	transition: font-size 0.3s, color 0.3s;
}

.isFocused {
	font-size: 13px;
	color: rgba($color-default-black, 0.6) !important;
	padding-bottom: 20px;
}

.action {
	display: block;
	padding: 16px;
	text-transform: uppercase;
	color: $color-white;
	font-size: 24px;
	background-color: $color-red;
	border-radius: 8px;
	width: 100%;
	font-weight: 700;
	text-align: center;
	box-sizing: border-box;
	transition: transform 0.3s;

	&:hover {
		transform: translateY(-3px);
		cursor: pointer;
	}
}

.consent {
	color: $color-red;

	a {
		color: $color-red;
		text-decoration: underline;
	}
}