@import "~/src/styles/style.scss";

.root {
	font-size: 12px;
	font-weight: 600;
	color: $color-gray;
	transition: background-color 0.3s ease-out, color 0.3s ease-out;
	background-color: transparent;
	border-radius: 4px;
	position: relative;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	line-height: 1;

	@include breakpoint(medium) {
		font-size: 14px;
	}

	@include breakpoint(large) {
		display: inline-flex;
	}

	@include breakpoint(xlarge) {
		display: flex;
		font-size: 16px;
		line-height: 20px
	}

	@include breakpoint(desktop) {
		font-size: 12px;
		line-height: 12px;
	}

	&:hover {
		background-color: $color-red;
		color: $color-white;
		border-color: transparent;
		
		.iconWrapper {
			opacity: 1;

			@include breakpoint(large) {
				opacity: 0;
			}

			@include breakpoint(xlarge) {
				opacity: 1;
			}
		}
	}

	&::after {
		content: '';
		position: absolute;
		width: calc(100% - 5px);
		justify-self: center;
		bottom: 0;
		border-bottom: 1px solid rgba($color-gray, 0.3);
	}
}

.iconWrapper {
	width: 16px;
	height: 16px;
	padding-right: 4px;
	opacity: 0;
	transition: all 0.3s ease-out;
}

.link {
	padding: 8px 0 8px 4px;
	color: inherit;
	width: 100%;

	@include breakpoint(medium) {
		padding: 12px 0 12px 6px;
	}

	@include breakpoint(large) {
		padding: 11px 0 11px 4px;
	}

	@include breakpoint(xlarge) {
		padding: 13px 0 11px 4px;
	}

	@include breakpoint(desktop) {
		padding: 5px 0 5px 4px;
	}
}