@import "~/src/styles/style.scss";

.root {
	color: $color-black;
	display: flex;
	flex-direction: column;
	padding: 0 8px 32px 8px;
	margin-top: 32px;

	@include breakpoint(medium) {
		margin-top: 36px;
	}

	@include breakpoint(large) {
		margin-top: 48px;
		padding: 0 0 32px;
	}

	@include breakpoint(xlarge) {
		margin-top: 64px;
	}

	@include breakpoint(desktop) {
		margin-top: 96px;
		padding-bottom: 20px;
	}
}

.footerMenu {
	display: flex;
	flex-direction: column;

	@include breakpoint(large) {
		flex-direction: row;
		margin-top: 0;
		width: 63.4%;
		justify-content: flex-end;
	}

	@include breakpoint(desktop) {
		width: unset;
	}
}

.title {
	color: $color-black;
}

.logoWrapper {
	margin-bottom: 28px;

	@include breakpoint(large) {
		margin-bottom: 0;
		width: 36.6%;
	}

	@include breakpoint(xlarge) {
		margin-right: 266px;
	}

	@include breakpoint(desktop) {
		margin-right: 313px;
		width: unset;
	}

	svg {
		width: 174px;
		height: 11px;

		@include breakpoint(medium) {
			width: 230px;
			height: 14px;
		}

		@include breakpoint(large) {
			width: 208px;
			height: 13px;
		}

		@include breakpoint(desktop) {
			width: 325px;
			height: 20px;
		}
	}
}

.footerRow {
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	&:first-child {
		margin-bottom: 48px;

		@include breakpoint(large) {
			flex-direction: row;
			justify-content: space-between;
		}
	}
}

.footerRowCopyright {
	display: block;

	@include breakpoint(medium) {
		display: flex;
		flex-direction: row;
	}

	@include breakpoint(large) {
		justify-content: space-between;
	}
}

.lawBlock {
	margin-top: 32px;

	@include breakpoint(medium) {
		margin-top: 0;
		width: 61.8%;
	}

	@include breakpoint(large) {
		width: unset;
		display: flex;
	}
}

.copyright {
	@include breakpoint(medium) {
		width: 39.2%;
	}

	@include breakpoint(large) {
		width: unset;
		margin-right: 44px;
	}

	@include breakpoint(xlarge) {
		margin-right: 147px;
	}

	@include breakpoint(desktop) {
		margin-right: 524px;
	}
}