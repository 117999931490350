@import "~/src/styles/style.scss";

.footerMenuColumn {
	display: flex;
	min-width: 204px;
	
	&:not(:last-child) {
		margin-bottom: 22px;

		@include breakpoint(large) {
			margin-bottom: 0;
			margin-right: 32px;

			&:nth-child(2) {
				margin-right: 60px;
			}
		}

		@include breakpoint(xlarge) {
			margin-right: 36px;

			&:nth-child(2) {
				margin-right: 36px;
			}
		}

		@include breakpoint(desktop) {
			margin-right: 56px;
			min-width: 215px;

			&:nth-child(2) {
				margin-right: 56px;
			}
		}
	}

	@include breakpoint(large) {
		flex-direction: column;
		min-width: auto;
	}

	@include breakpoint(xlarge) {
		min-width: 204px;
	}
}

.title {
	color: $color-black;
	font-size: 16px;

	@include breakpoint(medium) {
		font-size: 18px;
	}

	@include breakpoint(large) {
		font-size: 20px;
		letter-spacing: 1px;
	}

	@include breakpoint(xlarge) {
		font-size: 24px;
		letter-spacing: 0.5px;
		line-height: 30px;
	}
}

.footerMenuColumnTitle,
.footerMenuitem {
	color: $color-gray;
	display: inline-block;
	line-height: 1;
}

.footerMenuColumnTitle {
	font-weight: 600;
	line-height: 29px;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	width: 43%;

	@include breakpoint(medium) {
		width: 38.2%;
	}

	@include breakpoint(large) {
		width: unset;
		margin-bottom: 14px;
	}

	@include breakpoint(xlarge) {
		margin-bottom: 22px;
	}

	@include breakpoint(desktop) {
		margin-bottom: 18px;
	}
}

.footerMenuitem {
	font-weight: 600;
	font-size: 12px;
	line-height: 13px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	border-bottom: 1px solid rgba($color-red, 0.3);
	margin-bottom: 10px;
}

.linksWrap {
	margin-top: 2px;
	width: 57%;

	@include breakpoint(medium) {
		width: 61.8%;
	}

	@include breakpoint(large) {
		width: unset;
	}
}