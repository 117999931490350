@import "~/src/styles/style.scss";

.root {
	padding: 16px 6px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: $color-white;
	max-width: 1640px;
	width: 100%;
	min-height: 56px;

	@include breakpoint(medium) {
		padding: 24px 8px;
		min-height: 80px;
	}

	@include breakpoint(large) {
		padding: 20px 0;
	}

	@include breakpoint(xlarge) {
		padding: 24px 0;
	}

	@include breakpoint(desktop) {
		padding: 20px 0;
		justify-content: unset;
	}
}

.logoWrapper {
	width: 133px;
	display: flex;
	align-items: center;
	flex-shrink: 0;

	@include breakpoint(medium) {
		width: 227px;
	}

	@include breakpoint(large) {
		width: 208px;
	}

	@include breakpoint(xlarge) {
		width: 277px;
	}

	@include breakpoint(desktop) {
		width: 25%;
		margin-right: 12%;
	}

	svg {
		width: 100%;
		height: 100%;
	}
}

.default .menuWrapper {
	opacity: 1;
	transition: 0.3s ease-out;
}

.root.scroll .menuWrapper {
	opacity: 1;
}

.menuDesktopWrapper {
	display: none;

	@include breakpoint(large) {
		display: flex;
		align-items: center;
	}
}

.burgerWrapper {
	@include breakpoint(large) {
		display: none;
	}
}

.buttonWrapper {
	display: none;

	@include breakpoint(large) {
		display: block;
	}

	@include breakpoint(desktop) {
		display: flex;
		flex-grow: 1;
		justify-content: flex-end;
	}
}