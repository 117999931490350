@import "~/src/styles/style.scss";

.root {
	top: 0;
	left: 0;
	width: 100vw;
	height: calc(100vh - calc(100vh - 100%));
	position: fixed;
	z-index: 999;
}

.bg {
	position: relative;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($color-default-black, 0.9);
}

.content {
	position: absolute;
	z-index: 3;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 340px;

	@include breakpoint(medium) {
		min-width: 478px;
	}
}

.buttonWrapper {
	max-width: 1440px;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	top: 40px;
	position: absolute;
	justify-content: flex-end;
	display: flex;
	z-index: 10;
}

.close {
	width: 22px;
	height: 22px;
	color: $color-black;
	cursor: pointer;
	margin-right: 20px;

	svg {
		path {
			transition: fill 0.3s;
			fill: $color-red;
		}
	}

	&:hover {
		svg {
			path {
				fill: $color-red;
			}
		}
	}
}
