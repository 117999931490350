//Цвет
$color-white: #fff;
$color-black: #10100e;
$color-black-light: #161616; 
$color-default-black: #000;
$color-light-black: #212121;
$color-gray: #2e2e2c;
$color-red-light: #ff1b1b;
$color-red: #ff1b1c;
$color-red-dark: #e40001;
$color-gray-light: #e1e1e1;
$color-blue: #669bf7;
$color-red-error: #b3261e;

//Сетка
$breakpoints: (
	small: 375px,
	medium: 560px,
	large: 960px,
	xlarge: 1200px,
	desktop: 1440px
);