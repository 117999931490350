@import "~/src/styles/style.scss";

.copyright {
	opacity: 0.3;
	font-weight: 500;
	font-size: 10px;
	line-height: 14px;

	@include breakpoint(medium) {
		font-size: 14px;
	}

	@include breakpoint(large) {
		font-size: 12px;
		line-height: 14px;
	}

	@include breakpoint(xlarge) {
		font-size: 14px;
		line-height: 14px;
	}

	@include breakpoint(desktop) {
		font-size: 12px;
		line-height: 12px;
	}
}