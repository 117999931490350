@import "~/src/styles/style.scss";
@import "~/src/styles/_normalize.scss";

body {
	background-color: $color-white;
	font-family: "Inter", sans-serif;
}

.wrapper {
	height: 100%;
	max-width: 1640px;
	margin: 0 auto;
	padding: 0 4px;

	@include breakpoint(large) {
		padding: 0 20px;
	}
}

.content {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 100vh;
}

.main {
	flex: 1 0 auto;
}

header {
	position: sticky;
	top: 0;
	z-index: 50;
}

footer {
	flex: 0 0 auto;
}