@import "~/src/styles/style.scss";

.root {
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
	height: 100%;

	&:hover {
		.inputText {
			color: $color-red;
		}

		.icon {
			opacity: 1;
		}
	}

	&::after {
		content: attr(message);
		position: absolute;
		left: 44px;
		bottom: 10px;
		color: $color-red;
		font-weight: 500;
		font-size: 14px;
		display: none;

		@include breakpoint(medium) {
			left: 51px;
			font-size: 18px;
			bottom: 7px;
		}

		@include breakpoint(large) {
			left: 44px;
			font-size: 16px;
			bottom: 8.5px;
		}

		@include breakpoint(xlarge) {
			left: 52px;
			font-size: 18px;
			line-height: 120%;
			bottom: 10px;
		}

		@include breakpoint(desktop) {
			border-radius: 8px;
			left: 46px;
		}
	}

	@include breakpoint(large) {
		min-height: 50px;
	}

	@include breakpoint(xlarge) {
		min-height: 62px;
	}

	@include breakpoint(desktop) {
		min-height: unset;
		max-height: 56px;
	}
}

.message {
	&::after {
		display: block;
	}
}

.messageDisable {
	&::after {
		display: none;
	}
}

.inputContent {
	position: absolute;
	left: 8px;
	display: flex;
	align-items: center;

	@include breakpoint(medium) {
		left: 12px;
	}

	@include breakpoint(large) {
		left: 11px;
	}

	@include breakpoint(desktop) {
		left: 16px;
	}
}

.input {
	width: 100%;

	input {
		padding: 25px 13.5px 10px 44px;
		display: flex;
		align-items: center;
		position: relative;
		background-color: rgba($color-red, 0.1);
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		color: $color-red;
		font-weight: 500;
		font-size: 16px;
		border-radius: 6px;
		font-feature-settings: "case" on;

		@include breakpoint(medium) {
			padding: 28px 24px 7.5px 51px;
			font-size: 18px;
		}

		@include breakpoint(large) {
			padding: 22.5px 24px 7.5px 44px;
			font-size: 16px;
		}

		@include breakpoint(xlarge) {
			min-width: unset;
			padding: 27px 24px 8px 52px;
			font-size: 18px;
			line-height: 120%;
		}

		@include breakpoint(desktop) {
			border-radius: 8px;
			padding: 25px 24px 12px 46px;
		}
	}
}

.error {
	input {
		background-color: rgba($color-red, 0.2);
		border: 1px solid $color-red-error;
		margin: -1px;

		@include breakpoint(desktop) {
			margin: -2px;
			border: 2px solid $color-red-error;
		}
	}
}

.inputText {
	color: rgba($color-red, 0.5);
	transition: color 0.3s;
	font-weight: 500;
	transition: font-size 0.3s, color 0.3s;
	font-size: 14px;
	padding-left: 18px;

	@include breakpoint(medium) {
		font-size: 18px;
		padding-left: 18px;
	}

	@include breakpoint(large) {
		font-size: 16px;
		line-height: 120%;
		padding-left: 15px;
	}

	@include breakpoint(xlarge) {
		padding-left: 20px;
		font-size: 18px;
		margin-bottom: 0;
	}

	@include breakpoint(desktop) {
		padding-left: 4px;
		padding-top: 1.5px;
		font-size: 17px;
	}
}

.isFocused {
	font-size: 11px;
	color: rgba($color-default-black, 0.6) !important;
	padding-bottom: 22px;

	@include breakpoint(medium) {
		font-size: 14px;
	}

	@include breakpoint(large) {
		padding-bottom: 22px;
		font-size: 11px;
	}

	@include breakpoint(desktop) {
		font-size: 13px;
		line-height: 100%;
		padding-bottom: 28px;
	}
}

.icon {
	display: flex;
	align-items: center;
	transition: opacity 0.3s ease;
	opacity: 0.5;

	@include breakpoint(medium) {
		font-size: 14px;
	}

	@include breakpoint(large) {
		font-size: 11px;
	}

	@include breakpoint(desktop) {
		width: 26px;
		height: 26px;
	}

	svg {
		width: 18px;
		height: 18px;

		@include breakpoint(medium) {
			width: 21px;
			height: 21px;
		}

		@include breakpoint(large) {
			width: 18px;
			height: 18px;
		}

		@include breakpoint(xlarge) {
			width: 21px;
			height: 21px;
		}

		@include breakpoint(desktop) {
			width: 20px;
			height: 20px;
		}
	}
}

.isIconActive {
	opacity: 1;
}

.checkboxWrap {
  padding-bottom: 6px;
	position: relative;
	display: flex;
	gap: 10px;

	&:not(:last-child) {
		padding-bottom: 24px;
	}

	cursor: pointer;

	input {
		display: none;

		&:checked + .holder {
			svg {
				opacity: 1;
			}
		}
	}
	
	.holder {
		display: flex;
		align-items: center;
    justify-content: center;
		
		border: 1px solid #ff1b1c;
		border-radius: 4px;
		
		width: 20px;
		height: 20px;
		
		min-width: 20px;
		min-height: 20px;

		svg {
			width: calc(100% - 4px);
			height: calc(100% - 4px);
			opacity: 0;
			transition-duration: 0.3s;
			transition-property: opacity;
		}
	}

	.label {
		color: #ff1b1c;
		
		a {
			color: #ff1b1c;
			text-decoration: underline;
		}
	}
	
	.errorMsg {
		position: absolute;
		top: calc(100% - 24px);
		font-size: 14px;
		left: 0;
		color: #b3261e;
		opacity: 0;

		transition-property: opacity;
		transition-duration: 0.3s;

		pointer-events: none;

		&.errorVisible {
			opacity: 1;
		}
	}
}
