@import "~/src/styles/style.scss";

.root {
    background-color: $color-white;
    padding: 24px;
    width: calc(100% - 20px);
    margin: 0 auto;
	border-radius: 10px;

    @include breakpoint(medium) {
        max-width: 440px;
        width: 100%;
        margin: unset;
    }

    @include breakpoint(large) {
        max-width: 502px;
        padding: 32px;
    }

    @include breakpoint(xlarge) {
        max-width: 520px;
        padding: 24px;
    }

    @include breakpoint(desktop) {
        padding: 66px 48px;
        max-width: 686px;
    }
}

.title {
	font-weight: 700;
    font-size: 26px;
    line-height: 26px;
	color: $color-red;
	margin-top: 0;
	letter-spacing: -1px;

	@include breakpoint(medium) {
		font-size: 32px;
		line-height: 32px;
		letter-spacing: 0;
	}

	@include breakpoint(large) {
		font-size: 26px;
		line-height: 26px;
		letter-spacing: -0.2px;
	}

	@include breakpoint(xlarge) {
		font-size: 32px;
		line-height: 32px;
	}

	@include breakpoint(desktop) {
		font-size: 36px;
		line-height: 36px;
		margin-bottom: 4px;
	}
}