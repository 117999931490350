@import "~/src/styles/style.scss";

.root {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
    width: 100%;
	height: 100%;
	padding: 16px 12px;
	border-radius: 10px;
	background-color: $color-gray;
	color: $color-red;
}

.isActive {
	background-color: $color-red;
}

.iconWrapper {
	svg {
		path {
			fill: $color-red;
		}
	}
}

.linkActive {
	color: $color-white;
}

.iconActive {
	svg {
		path {
			fill: $color-white;
		}
	}
}