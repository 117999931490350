@import "~/src/styles/style.scss";

.wrapper {
	position: sticky;
	z-index: 100;
	bottom: 20px;
	left: 20px;
	display: flex;
	align-items: center;
	width: 100%;
	background-color: #fff;
	padding: 24px;
	border: none;
	border-radius: 10px;
	opacity: 0.9;

	span {
		color: #ff1b1c;
		margin-right: 20px;

		a {
			font-weight: bold;
			color: red;
			text-decoration: underline;
		}
	}

	button {
		padding: 10px 20px;
		border: none;
		border-radius: 8px;
		background-color: #ff1b1c;
		color: #fff;
		line-height: 28px;
    	font-size: 22px;
		font-weight: bold;
		cursor: pointer;
	}
}