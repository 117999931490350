@import "~/src/styles/style.scss";

.closeMenu {
	display: none;
	width: 16px;
	height: 16px;

	@include breakpoint(medium) {
		width: 21px;
		height: 21px;
	}

	svg {
		width: 100%;
		height: 100%;
	}
}

.menu {
	width: calc(100% - 8px);
	height: calc(100% - 50px);
	position: fixed;
	z-index: 50;
	background: $color-black;
	left: 4px;
	top: 56px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	opacity: 0;
	transition: opacity 0.3s ease-out;
	visibility: hidden;
	gap: 4px;
	pointer-events: none;

	&.open {
		opacity: 1;
		visibility: visible;
		pointer-events: all;
	}

	@include breakpoint(medium) {
		height: calc(100% - 80px);
		top: 80px;
	}
}

.openMenu {
	display: flex;
	align-items: center;
	width: 25px;
	height: 21px;

	@include breakpoint(medium) {
		width: unset;
		height: unset;
	}

	svg {
		width: 100%;
		height: 100%;

		@include breakpoint(medium) {
			width: unset;
			height: unset;
		}
	}
}

.burgerOpen {
	display: none;
}

.openMenuM {
	display: flex;
	align-items: center;

	svg {
		width: 15px;

		@include breakpoint(small) {
			width: auto;
		}
	}
}

.menuItem,
.mainItem {
	font-size: 26px;
	width: calc(50% - 8px);
	min-height: 200px;
	font-weight: 700;

	@include breakpoint(medium) {
		font-size: 32px;
	}
}


.button {
	background: $color-red;
	color: $color-white;
	text-transform: uppercase;
	border-radius: 6px;
	padding: 17px;
	width: 100%;
	height: 52px;
	text-align: center;
	font-weight: 700;
	font-size: 16px;
	margin: 6px 6px 16px 6px;
	align-self: end;
}
