@import "~/src/styles/style.scss";

.root {
	display: block;

	&:not(:last-child) {
		margin-bottom: 4px;

		@include breakpoint(large) {
			margin-right: 30px;
		}
	}

	@include breakpoint(large) {
		line-height: 0;
	}
}

.footerLink {
	color: $color-gray;
	opacity: 0.5;
	font-weight: 500;
	font-size: 10px;
	line-height: 14px;

	@include breakpoint(medium) {
		font-size: 12px;
	}

	@include breakpoint(xlarge) {
		font-size: 14px;
	}

	@include breakpoint(desktop) {
		font-size: 12px;
	}
}