@import "~/src/styles/style.scss";

.root {
	padding: 0 10px;
}

.headerMenuLink {
	color: rgba($color-black, 0.8);
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	text-transform: uppercase;
	opacity: 0.8;
	padding-bottom: 10px;
	border-bottom: 2px solid rgba($color-red, 0);
	transition: color 0.6s, border-color 0.6s;

	&:hover {
		color: rgba($color-black, 1);
		border-bottom: 2px solid rgba($color-red, 0.3);
	}

	@include breakpoint(xlarge) {
		font-size: 18px;
	}
}

.active {
	color: rgba($color-black, 1);
	border-bottom: 2px solid rgba($color-red, 1);
}
