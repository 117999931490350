@use '~/src/styles/_config';

@mixin breakpoint($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media (min-width: map-get($breakpoints, $breakpoint)) {
			@content;
		}
	}
}

@mixin fisrtBlockMargin {
	margin-top: 16px;
	margin-bottom: 32px;

	@include breakpoint(medium) {
		margin-bottom: 36px;
	}

	@include breakpoint(large) {
		margin-top: 32px;
		margin-bottom: 48px;
	}

	@include breakpoint(xlarge) {
		margin-bottom: 64px;
	}

	@include breakpoint(desktop) {
		margin-bottom: 96px;
		margin-top: 48px;
	}
}

@mixin blockMargin {
	margin-bottom: 32px;

	@include breakpoint(medium) {
		margin-bottom: 36px;
	}

	@include breakpoint(large) {
		margin-bottom: 48px;
	}

	@include breakpoint(xlarge) {
		margin-bottom: 64px;
	}

	@include breakpoint(desktop) {
		margin-bottom: 96px;
	}
}

@mixin font-face (
	$font-name,
	$file-name,
	$weight: normal,
	$style: normal)
	{
	@font-face {
		font-family: quote($font-name);
		src: url($file-name + '.woff2') format('woff2');
		font-weight: $weight;
		font-style: $style;
	}
}