@import "~/src/styles/style.scss";

.button {
	background: $color-red;
	color: $color-white;
	border-radius: 8px;
	font-weight: 700;
	font-size: 16px;
	line-height: 110%;
	text-transform: uppercase;
	padding: 8px 12px;
	transition: all 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 125px;

	@include breakpoint(medium) {
		padding: 12px 24px;
	}

	@include breakpoint(large) {
		max-width: 129px;
		line-height: 110%;
		padding: 8px 12px;
	}

	@include breakpoint(xlarge) {
		max-width: 172px;
		font-size: 20px;
		padding: 16px 24px;
	}

	@include breakpoint(desktop) {
		padding: 16px;
		line-height: 28px;
		font-size: 22px;
	}
}

.isHeader {
	@include breakpoint(xlarge) {
		max-width: unset;
		font-size: 20px;
		padding: 16px 24px;
	}

	@include breakpoint(desktop) {
		padding: 16px;
		line-height: 28px;
		font-size: 22px;
	}

	@include breakpoint(desktop) {
		padding: 10px 16px;
		max-width: 165px;
	}
}

.button:hover {
	transform: translateY(-3px);
	cursor: pointer;
}