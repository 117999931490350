@import "~/src/styles/style.scss";

.root {
	display: flex;
}


.linkWrapper {
	margin-right: 24px;
	flex-shrink: 0;

	&:last-child {
		margin-right: 0;
	}
}